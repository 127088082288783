html {
  background: transparent;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: max(100vh, max-content);
  background: transparent;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}